.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #09d3ac;
}

.textCenter {
    text-align: center;
}

.alignMiddle {
    vertical-align: middle;
}

.table-area {
    position: relative;
    z-index: 0;
    margin-top: 50px;
}

.fixHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    background: #eee;
    table-layout: fixed;
    display: table;
}

.month {
    background: coral;
}

a.btn-xs {
    margin-top: -10px;
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .9;
    border-radius: .2rem;
    white-space: normal !important;
    display: flex;
}

/*
.day-sab{
    background: mistyrose;
}
*/

.day-dom{
    background: mistyrose;
}
